import React from "react"

import Layout from "components/layout"
import { Accordion } from "components/anti/accordion/accordion"
import { Button } from "components/anti/buttons/buttons"
import { useScrollAnim } from "src/components/hooks/hooks"

import iconSvg from "assets/img/icon-register.svg"

const RegistrationPromoPage = () => {
  const [trigger, anim] = useScrollAnim()

  const listStep = [
    "Daftarkan dirimu sebagai member Dompet Aman",
    "Selesaikan proses pendaftaran dan buat akunmu",
    "Daftar sebagai Referal ",
  ]

  const listTnc = [
    "Promosi ini terbuka untuk individu berusia 17-40 tahun yang mengunduh aplikasi Dompet Aman dan mendaftar sebagai member baru.",
    "Peserta harus memberikan informasi yang akurat dan valid saat proses pendaftaran.",
    "Peserta akan mendapatkan satu kupon undian door prize setelah berhasil mendaftar sebagai member baru.",
    "Pemenang door prize akan dipilih melalui undian acak yang dilakukan oleh Dompet Aman.",
    "Hadiah door prize mencakup berbagai macam hadiah bernilai hingga jutaan rupiah, sebagaimana dijelaskan dalam promosi.",
    "emenang akan dihubungi melalui informasi kontak yang diberikan saat proses pendaftaran.",
    "Pemenang harus mengklaim hadiah mereka dalam jangka waktu yang ditentukan, seperti yang diinformasikan oleh Dompet Aman.",
    "Hadiah tidak dapat dipindahkan dan tidak dapat ditukar dengan uang tunai atau hadiah lainnya.",
    "Dompet Aman berhak untuk mengubah atau mengakhiri promosi ini kapan saja tanpa pemberitahuan sebelumnya.",
  ]
  return (
    <Layout noCover>
      <section className="sc-registration-promo py-main" ref={trigger}>
        <div className="container">
          <div className="mb-5">
            <h1 className={`${anim(1)}`}>
              Mau menangkan total hadiah senilai Rp 10.000.000* ?
            </h1>
            <h2 className={`h1 font-weight-normal ${anim(2)}`}>
              Begini caranya:
            </h2>
          </div>
          <ul className={`registration-step pb-5 ${anim(3)}`}>
            {listStep.map((item, i) => (
              <li className="step-item" key={i}>
                <div className="step-icon mb-4">
                  <img src={iconSvg} alt="icon" className="img-fluid" />
                </div>
                <h5 className="font-weight-normal">{item}</h5>
                {i === 2 && (
                  <Button
                    variant="primary"
                    className="regis-now"
                    link="https://mailchi.mp/dompetaman/ori2-bergabung-jadi-referral"
                  >
                    Daftar Sekarang
                  </Button>
                )}
              </li>
            ))}
          </ul>
          <div className={`registration-tnc ${anim(5)}`}>
            <Accordion
              variant="boxless"
              title="Syarat & Ketentuan"
              expandedKey={"tnc"}
              className="accordion-tnc"
            >
              <ul className="">
                {listTnc.map((li, i) => (
                  <li key={i}>{li}</li>
                ))}
              </ul>
            </Accordion>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default RegistrationPromoPage
